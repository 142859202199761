.footer-text-left {
    box-sizing: border-box;
    flex: 1 1;
    font-size: 15px;
}
  
.footer-text-left strong {
    color: #1890ff;
}
  
.footer-btns-right{
    display: flex;
    justify-content: space-between;
    width: 150px;
}
  
.footer-btns-right button {
    border: none;
    color: #fff;
    background: #1890ff;
    border-color: #1890ff;
    height: 40px;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 2px;
    align-items: center;

    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
}

.footer-btns-right button:focus{
    border: none;
    outline: 0;
}

.footer-opened {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 0 24px;
    line-height: 44px;
    background: #fff;
    border-top: 1px solid #f0f0f0;
    box-shadow: 0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%);
    transition: width .1.8s cubic-bezier(.645,.045,.355,1);
    width: calc(100% - 200px);
}

.footer-closed {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 0 24px;
    line-height: 44px;
    background: #fff;
    border-top: 1px solid #f0f0f0;
    box-shadow: 0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%);
    transition: width .1.8s cubic-bezier(.645,.045,.355,1);
    width: calc(100% - 80px);
}