@import '~antd/dist/antd.css';

.login-container {
  display: flex;
  flex-direction: column;
  width: 350px; 
  position: relative;
  margin: auto;
  top: 250px;
  /* float: right;
  margin-right: 100px; */
}

.background-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  margin-right: 0;
  object-fit: cover;

  background: url('../../assets/nasa.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100vh;
}