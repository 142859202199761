@import '~antd/dist/antd.css';

.dashboard-container .ant-layout {
  background: #fff;
}

.dashboard-container .side {
  position: fixed;
  z-index: 2;
  left: 0%;
  /* top: 9%; */
  width: 10%;
  height: 100%;
  overflow-y: hidden;
}

.dashboard-container .body {
  position: absolute;
  left: 8%;
  /* top: 14%; */
   width: 90%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
} 

.dashboard-container  .ant-layout-header {
  height: 64px;
  padding: 0 50px;
}

.dashboard-container .ant-dropdown-menu {
  border-radius: 10px;
}

.opened {
  margin-left: 200px;
  transition: all 0.2;
  padding: 16px 16px 0 16px;
}

.closed {
  margin-left: 80px;
  transition: all 0.2;
  padding: 16px 16px 0 16px;
  width: 100%;
  height: 100%;
}